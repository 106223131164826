<template>
  <div class="loading-indicator">
      <div class="loading-content show-message" data-overlay="loading-content">
        <div role="status" class="spinner-border">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="loading-message" data-overlay="loading-message" />
      </div>
    </div>
</template>

<script>
export default {
  mounted() {
    document.querySelector("html").style.overflowY = "hidden";
  },
  beforeDestroy() {
    document.querySelector("html").style.overflowY = "auto";
  }
};
</script>
