import Vue from "vue";
import Router from "vue-router";
import * as menu from "~/menu-system/config/routes.js";
//import * as absence from "~/absence-system/config/routes.js";
import * as recruiting from "~/recruiting-system/config/routes.js";
import * as persis from "~/persis-system/config/routes.js";
import * as security from "~/security-system/config/routes.js";
import * as config from "~/config-system/config/routes.js";
import store from "@/store/store";

Vue.use(Router);

//const definitions = [menu, absence, recruiting, persis];
const definitions = [menu, persis, security, recruiting, config];
const routes = definitions.reduce((carry, current) => {
  return [...carry, ...current.routes];
}, []);

export const redirectIfNoAccess = (hasPermission, next, successParams) => {
  if (hasPermission) {
    successParams ? next(successParams) : next();
  } else {
    next({
      name: "system.feedback",
      replace: true
    });
  }
};

routes.push({
  path: "*",
  name: "page.not.found",
  component: () => import("+/components/feedback/SystemFeedback.vue")
});

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.meta.isLoading) {
    store.dispatch("loader/isLoading", to.meta.isLoading);
  }
  store.dispatch("mainIframeStore/setLastNavigationUrl", from.fullPath);
  next();
});

router.afterEach((to, from) => {
  if (!to.meta.isLoading) {
    store.dispatch("loader/isLoading", false);
  }
  store.dispatch("breadcrumb/updateBreadcrumb", {
    route: to,
    menu: store.getters["menu/getAtomMenuFlat"]
  });
});

export default router;
