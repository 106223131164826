<template>
  <a-dropdown trigger-class="metalink">
    <img
      id="user-dropdown-image-id"
      slot="triggerContent"
      :src="userImageSrc"
      class="user-image user-image--xs"
    />
    <div class="dropdown-item card border-0">
      <div class="card-body py-2 m-0">
        <div class="card-title mb-0" v-text="getUserNameFromToken()" />
        <span
          class="card-subtitle text-pf-muted"
          v-text="getUserEmailFromToken()"
        />
      </div>
    </div>
    <div class="dropdown-divider" />
    <button
      v-t="'Components.MetalinkNavigation.MyProfile'"
      type="button"
      class="dropdown-item"
      :title="$t('Components.MetalinkNavigation.MyProfile')"
      @click="navigateTo('/config/userprofile')"
    />
    <button
      v-if="isImpersonationAllowed() && hasImpersonationRole()"
      v-t="'Components.MetalinkNavigation.Impersonate'"
      type="button"
      class="dropdown-item"
      :title="$t('Components.MetalinkNavigation.Impersonate')"
      @click="navigateTo('/security/user-impersonation')"
    />
    <button
      v-t="'Components.MetalinkNavigation.Logout'"
      class="dropdown-item"
      :title="$t('Components.MetalinkNavigation.Logout')"
      @click="logout()"
    />
  </a-dropdown>
</template>

<script>
import Dropdown from "+/components/dropdown/Dropdown";
import { keycloakLogout, createLogoutUrl } from "@/config/keycloak/keycloak";
import {
  getUserName,
  getUserEmail,
  hasManagementRole
} from "@/config/keycloak/keycloakUser";
import { navigationBus } from "+/components/navigation/utils/navigationBus.js";
import { mapGetters } from "vuex";
import { isUserImpersoniated } from "@/config/keycloak/keycloakUser";
import SettingsQuery from "../graphql/SettingsQuery.gql";
import apolloProvider from "@/apollo/provider.js";

export default {
  components: {
    "a-dropdown": Dropdown
  },
  props: {
    userName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      impersonationAllowed: this.getKeycloakImpersonationConfiguration()
    };
  },
  computed: { ...mapGetters("userProfile", { userImageSrc: "userImageSrc" }) },
  methods: {
    canImpersonate() {
      return (
        this.isImpersonationAllowed() &&
        this.hasImpersonationRole() &&
        this.hasManageUsersRole() &&
        !this.userIsImpersoniated()
      );
    },
    logout() {
      navigationBus.$emit("navigation-item-click");
      const successCallback = () => {
        window.location.href = createLogoutUrl({
          redirectUri: window.location.origin
        });
      };
      keycloakLogout(successCallback, null, true);
    },
    isImpersonationAllowed() {
      return this.impersonationAllowed;
    },
    hasImpersonationRole() {
      return hasManagementRole("impersonation");
    },
    hasManageUsersRole() {
      return hasManagementRole("manage-users");
    },
    userIsImpersoniated() {
      return isUserImpersoniated();
    },
    getUserNameFromToken() {
      return getUserName();
    },
    getUserEmailFromToken() {
      return getUserEmail();
    },
    navigateTo(path) {
      this.$router.push({ path });
    },
    getKeycloakImpersonationConfiguration() {
      apolloProvider.clients.config
      .query({
        query: SettingsQuery
      })
      .then(
        ({ data: { getSettings: { keycloakEnableUserImpersonation } } }) => {
          this.impersonationAllowed = keycloakEnableUserImpersonation;
        }
      );
    }
  }
};
</script>
