const state = {
  isLoading: false
};

const getters = {
  isLoading: (state) => state.isLoading
};

const mutations = {
  isLoading: (state, payload) => (state.isLoading = payload)
};

const actions = {
  isLoading: ({ commit }, payload) => {
    commit("isLoading", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
