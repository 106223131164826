import { createRestClientByModuleName } from "@/config/rest/restUtils";

const persisClient = createRestClientByModuleName("persis");

const translationEnpoints = {
  translations: (locale = "de") => persisClient.get(`/translations/${locale}`),
  defaultLocale: () => persisClient.get("/languages/default"),
  i18nSettings: () => persisClient.get("/i18n-settings")
};

export const persis = {
  ...translationEnpoints,
  verifyEmployeeId: () => persisClient.get("/checkEmployee")
};
