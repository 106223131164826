<template>
  <DefaultLayout />
</template>

<script>
import DefaultLayout from "+/layouts/DefaultLayout";

export default {
  name: "App",
  components: { DefaultLayout },
  meta: {
    title: "Persis"
  }
};
</script>
