import { createRestClientByModuleName } from "@/config/rest/restUtils";
const securityClient = createRestClientByModuleName("security-system");

export const security = {
  sendPasswordResetMail: (userId) =>
    securityClient.get(`/password/reset/mail/${userId}`),
  generatePasswordResetLetter: (userId) =>
    securityClient.get(`/password/reset/letter/${userId}`),
  performImpersonate: (userId) => securityClient.get(`/impersonate/${userId}`)
};
