<template>
  <div>
    <a-navigation id="atom-main-navigation" />
    <div id="app">
      <div
        id="app-header"
        class="app-bar"
        :class="{ 'mobile-app-bar': isMobile }"
      >
        <a-logo :is-mobile="isMobile" />
        <a-metalink-navigation />
      </div>
      <vue-snotify />
      <div
        id="atom-main"
        :class="{ 'navigation-opened': !isNavigationCollapsed }"
      >
        <a-loader v-if="isLoading" />
        <div id="atom-main-container" :class="getMainContainerClass($route)">
          <h1 v-if="$route.meta.title" v-t="$route.meta.title" />
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ANavigation from "+/components/navigation/Navigation";
import AMetalinkNavigation from "+/components/navigation/metalinkNavigation/MetalinkNavigation";
import Logo from "+/components/navigation/logo/Logo";
import Loader from "+/components/loader/Loader.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    "a-navigation": ANavigation,
    "a-metalink-navigation": AMetalinkNavigation,
    "a-logo": Logo,
    "a-loader": Loader
  },
  data: () => ({
    breakpointMobile: 0
  }),
  computed: {
    ...mapGetters("loader", ["isLoading"]),
    ...mapGetters("menu", ["isNavigationCollapsed"]),
    ...mapGetters("dom", ["isMobile"])
  },
  meta() {
    return {
      title: this.$t(this.$route.meta.title)
    };
  },
  created() {
    this.setFavicon()
  },
  mounted() {
    this.getBreakpointMobile();
    this.setMobileState();
    window.addEventListener("resize", () => {
      this.setMobileState();
    });
    window.addEventListener("message", this.handleMessage);
  },
  methods: {
    ...mapActions("dom", ["setIsMobile"]),
    getBreakpointMobile() {
      const style = getComputedStyle(document.documentElement);

      const breakpointMobile =
        style.getPropertyValue("--js-breakpoint-mobile") || "900";

      this.breakpointMobile = parseInt(breakpointMobile.replace("px", ""));
    },
    setMobileState() {
      this.setIsMobile(window.innerWidth < this.breakpointMobile);
    },
    getMainContainerClass(route) {
      return this.isPersisMainRoute(route)
        ? "fusion-main-container-padding"
        : "container-md atom-main-container atom-main-container-padding";
    },
    isPersisMainRoute(route) {
      const routeName = route.name;
      return (
        routeName === "mainFnAction" ||
        routeName === "mainFn" ||
        routeName === "main"
      );
    },
    setFavicon() {
      this.$rest.config
        .getSystemSetting("html.favicons.code")
        .then((response) => {
          let favicon = String(response.data)
          if (!favicon) {
            favicon = "<link href=\"/persis/favicon.ico\" rel=\"shortcut icon\">";
          }
          document.head.insertAdjacentHTML("beforeend", favicon);
        })
        .catch((error) =>
          window.atomlog(`getSystemSetting: ${error}`, "error")
        );
    },
    handleMessage(event) {
      if (event.data == "persis_frontend_reload") {
        window.location.reload();
      }
      if (event.data.action && event.data.action == "persis_frontend_reload"){
        if (event.data.targetLocation){
          window.location.href = event.data.targetLocation;
        } else {
          window.location.reload();
        }
      }
    }
  }
};
</script>