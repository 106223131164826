import { ApolloLink } from "apollo-link";

/**
 * Greift auf den ApolloCache zu und überschreibt den enthaltenen Datensatz mit der response vom Server
 * @param operation ApolloLink Parameter der execute- Methode
 * @param response Datensatz vom Server
 */
const updateCacheByQuery = (operation, response) => {
  const context = operation.getContext();
  const cache = context.cache;
  const responseDataKey = Object.keys(response.data)[0];
  if (!context.updateCache) {
    window.atomlog(
      "MutationLink: 'updateCache'- Objekt bei der Mution ist nicht vorhanden! Cache update abgebrochen!",
      "error"
    );
    return;
  }

  const updateQueryName = context.updateCache.queryName;

  const variables = operation.variables.hasOwnProperty("input")
    ? operation.variables.input
    : operation.variables[Object.keys(operation.variables)[0]];

  if (updateQueryName && responseDataKey) {
    cache.writeQuery({
      query: context.updateCache.query,
      variables: variables,
      data: {
        [updateQueryName]: response.data[responseDataKey]
      }
    });
  } else {
    window.atomlog(
      "MutationLink: 'queryName' oder 'responseDataKey' ist nicht vorhanden! Cache update abgebrochen!",
      "error"
    );
  }
};

/**
 * Löscht einen Datensatz im ApolloCache
 * @param operation ApolloLink Parameter der execute- Methode
 * @param response Datensatz vom Server
 */
const deleteCacheEntry = (operation, response) => {
  const context = operation.getContext();
  const cache = context.cache;
  const deleteQueryName = context.updateCache.queryName;
  const id = context.updateCache.delete.id;

  const key = `${deleteQueryName}:${id}`;
  if (cache.data[key]) {
    cache.data.delete(key);
  } else {
    window.atomlog(
      "MutationLink: Eintrag zum löschen wurde im Cache nicht gefunden!",
      "error"
    );
  }
};

const isMutationRequest = (query) =>
  query &&
  query.definitions[0] &&
  query.definitions[0].operation === "mutation";

const isDeletingMutation = (context) =>
  context &&
  context.updateCache &&
  context.updateCache.hasOwnProperty("delete") &&
  context.updateCache.delete.hasOwnProperty("id");

export default new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (isMutationRequest(operation.query) && !response.errors) {
      if (
        response.data.hasOwnProperty("validationErrors") &&
        Object.keys(response.data.validationErrors.length > 0)
      ) {
        return response;
      }

      if (isDeletingMutation(operation.getContext())) {
        deleteCacheEntry(operation, response);
      } else {
        updateCacheByQuery(operation, response);
      }
    }
    return response;
  });
});
