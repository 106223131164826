<template>
  <div class="responsive-logo-trigger">
    <div v-if="!isMobile" class="menu-start">
      <div class="menu-start-icon" @click="navigateHome" />
    </div>
    <button
      v-else
      type="button"
      class="responsive-navigation-burger-button"
      :class="{ open: isMobilNavigationOpen }"
      @click="toggleResponsiveNavigation()"
    >
      <span />
      <span />
      <span />
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("menu", ["isNavigationCollapsed"]),
    isMobilNavigationOpen() {
      return this.isMobile && !this.isNavigationCollapsed;
    }
  },
  methods: {
    ...mapActions("menu", ["setNavigationCollapsed"]),
    toggleResponsiveNavigation() {
      this.setNavigationCollapsed(!this.isNavigationCollapsed);
    },
    navigateHome() {
      this.$router.push({
        name: "main",
        query: {
          ...this.$route.query,
          // NavigationDuplicated Error wird durch einen kompletten reload der main- Komponente verhindert
          _forceNav: new Date().getTime()
        }
      });
    }
  }
};
</script>

<style lang="scss">
.menu-start-icon {
  background-image: var(--logo-url, url(../assets/logo_dots.svg));
}
</style>
