import axios from "axios";
import { getKeycloakToken } from "@/config/keycloak/keycloak.js";

/**
 * Host wird erst im Browser ermittelt. Dieses Vorgehen ist nur korrekt, solange die Rest SCS's auf dem gleichen Tomcat laufen
 */
const host = window.location.origin;

export const createRestClientByModuleName = (module) =>
  axios.create({
    baseURL: `${host}/${module}/private/instruction`
  });

/**
 * Setzt den keycloak Token vor jedem Rest call in den HTTP Headern
 */
export const initKeycloackTokenInterceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      const token = getKeycloakToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
};
