export const createNavigationLink = (option) => {
  const wrapperLink = document.createElement("a");
  wrapperLink.className = "power-user-navigation-select-option--wrapper";
  wrapperLink.href = option.path;
  wrapperLink.setAttribute("power-user-navigation-url", option.path);
  wrapperLink.setAttribute("data-navigation-href", option.path);

  const moduleImage = createModuleImage(option);
  const menuItem = createMenuItem(option);
  wrapperLink.appendChild(moduleImage);
  wrapperLink.appendChild(menuItem);
  return wrapperLink;
};

const createModuleImage = (option) => {
  const moduleImage = document.createElement("div");
  moduleImage.className = `power-user-nav-module nav-module-${option.module}`;
  return moduleImage;
};

const createMenuItem = (option) => {
  const menuItem = document.createElement("div");
  menuItem.classList.add("power-user-navigation-select-option--item");

  const menuItemTitle = document.createElement("div");
  menuItemTitle.textContent = option.text;
  const menuItemSubTitle = document.createElement("div");
  menuItemSubTitle.classList.add("module-name");
  menuItemSubTitle.textContent = option.levelOneTitle;
  menuItem.appendChild(menuItemTitle);
  menuItem.appendChild(menuItemSubTitle);
  return menuItem;
};
