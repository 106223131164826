const userImageRestUrl = "/config-system/private/instruction/profile/picture";
const state = {
  userImgSrc: userImageRestUrl
};

const getters = {
  userImageSrc: (state) => state.userImgSrc
};

const mutations = {
  setUserImgSrc: (state, payload) => (state.userImgSrc = payload),
  deleteUserImage: (state) =>
    (state.userImgSrc =
      "/config-system/private/instruction/profile/picture?delete")
};

const actions = {
  setUserImgSrc: ({ commit }, payload) => {
    commit("setUserImgSrc", payload);
  },
  deleteUserImg: ({ commit }) => {
    commit("deleteUserImage");
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
