const state = {
  isMobile: true
};

const getters = {
  isMobile: (state) => state.isMobile
};

const mutations = {
  setIsMobile: (state, payload) => (state.isMobile = payload)
};

const actions = {
  setIsMobile: ({ commit }, payload) => {
    commit("setIsMobile", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
