<template>
  <div class="row metalink-navigation-container">
    <div class="col"></div>
    <div class="col-auto align-self-center metalink-navigation">
      <span
        v-if="userIsImpersoniated()"
        class="badge badge-info"
        v-text="getImpersonationText()"
      />

      <a-dropdown icon="fa fa-cog" trigger-class="metalink">
        <a-metalink-dev-info-item />

        <router-link
          v-t="'Components.MetalinkNavigation.Sidemap'"
          class="dropdown-item"
          to="/menu/sitemap"
        />
        <router-link
          v-t="'Components.MetalinkNavigation.SystemInformation'"
          class="dropdown-item"
          to="/main/productinfo"
        />
      </a-dropdown>

      <a-user-image-menu />
    </div>
  </div>
</template>

<script>
import Dropdown from "+/components/dropdown/Dropdown";
import MetaLinkDevInfoButton from "+/components/navigation/metalinkNavigation/MetaLinkDevInfoButton";
import UserImageMenu from "+/components/navigation/metalinkNavigation/UserImageMenu";
import {
  getUserName,
  isUserImpersoniated
} from "@/config/keycloak/keycloakUser";
import { mapGetters } from "vuex";

export default {
  components: {
    "a-metalink-dev-info-item": MetaLinkDevInfoButton,
    "a-user-image-menu": UserImageMenu,
    "a-dropdown": Dropdown
  },
  data() {
    return {
      userMenuOpen: false
    };
  },
  computed: {
    ...mapGetters("dom", ["isMobile"])
  },
  methods: {
    userIsImpersoniated() {
      return isUserImpersoniated();
    },
    getUserNameFromToken() {
      return getUserName();
    },
    getImpersonationText() {
      return this.$t("Components.MetalinkNavigation.LoggedInAs", [
        this.getUserNameFromToken()
      ]);
    }
  }
};
</script>
