import { configure, extend } from "vee-validate";
import { passwordValidation, imageTypeValidation } from "./utils";
import {
  required,
  email,
  numeric,
  required_if,
  length,
  confirmed
} from "vee-validate/dist/rules";

const config = {
  classes: {
    valid: "is-valid",
    invalid: "is-invalid"
  },
  bails: false,
  skipOptional: true,
  mode: "eager",
  useConstraintAttrs: false
};

// Sets the options.
configure(config);

export const initializeValidation = (app) => {
  extend("required", {
    ...required,
    message: (_, values) => app.$t("Validations.Mandatory", values)
  });

  extend("email", {
    ...email,
    message: (_, values) => app.$t("Validations.Email", values)
  });

  extend("numeric", {
    ...numeric,
    message: (_, values) => app.$t("Validations.Numeric", values)
  });

  extend("required_if", {
    ...required_if,
    message: (_, values) => app.$t("Validations.ConditionalMandatory", values)
  });

  extend("length", {
    ...length,
    message: (_, values) => app.$t("Validations.MaxSize", values)
  });

  extend("confirmed", {
    ...confirmed,
    message: (_, values) => app.$t("Validations.Confirmation", values)
  });

  extend("password-confirmed", {
    ...confirmed,
    message: (_, values) => app.$t("Validations.PasswordConfirmation", values)
  });

  extend("password-policies-respected", (value, policies) => {
    return passwordValidation(app, value, policies);
  });

  extend("only-image-types", (value) => imageTypeValidation(value, app));
};
