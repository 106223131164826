<template>
  <div>
    <SidebarMenu
      ref="menu"
      :menu="atomMenu"
      :width-collapsed="responsiveCollapsedWidth"
      width="25rem"
      :collapsed="isNavigationCollapsed"
      show-one-child
      :disable-hover="disableHover"
      :hide-toggle="isMobile"
      @toggle-collapse="setNavigationCollapsed($event)"
      @item-click="onItemClick"
    >
      <span slot="dropdown-icon" class="dropdown-icon fa fa-angle-right" />
      <span slot="toggle-icon" class="toggle-icon fa fa-ellipsis-h" />
    </SidebarMenu>
    <a-power-user-navigation />
  </div>
</template>

<script>
import AtomMenuDataQuery from "./graphql/AtomMenuData.gql";

import { SidebarMenu } from "vue-sidebar-menu";
import APowerUserNavigation from "+/components/powerUserNavigation/PowerUserNavigation";
import { navigationBus } from "./utils/navigationBus.js";
import { getMapedMenuData, getAdjustedExternalUrl } from "./utils/navigationUtils";
import { isIosDevice } from "@/global-helper/plattformHelper.js";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SidebarMenu,
    "a-power-user-navigation": APowerUserNavigation
  },
  props: {
    disableHover: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("menu", ["getAtomMenu", "isNavigationCollapsed"]),
    ...mapGetters("dom", ["isMobile"]),
    atomMenu: function () {
      return getMapedMenuData(this.getAtomMenu);
    },
    responsiveCollapsedWidth() {
      return this.isMobile ? "0px" : "56px";
    }
  },
  methods: {
    ...mapActions("menu", ["setAtomMenu", "setNavigationCollapsed"]),
    ...mapActions("loader", ["isLoading"]),
    onItemClick(event, item) {
      if (item.hasOwnProperty("external") && item.external) {
        window.open(getAdjustedExternalUrl(item.path), "_blank");
      }
      if (!item.hasOwnProperty("child")) {
        if (this.isMobile) {
          this.setNavigationCollapsed(true);
        }
        this.closeNavOnIosDevices();
        navigationBus.$emit("navigation-item-click", event, item);
      }
      this.$emit("item-click", event, item);
    },
    closeNavOnIosDevices() {
      if (isIosDevice) {
        this.$refs.menu.mobileItem = null;
      }
    },
    addStartpageMenuItem(atomMenu) {
      atomMenu.unshift({
        class: "mobile-startpage-item",
        title: this.$t("Components.Breadcrumb.StartPage"),
        id: "Startpage",
        module: "Startpage",
        path: "/"
      });
    }
  },
  apollo: {
    $client: "menu",
    menu: {
      query: AtomMenuDataQuery,
      manual: true,
      result({ data, loading }) {
        if (!loading) {
          const { atomMenu } = data;
          this.addStartpageMenuItem(atomMenu);
          this.setAtomMenu(atomMenu);
        }
      },
      fetchPolicy: "no-cache"
    }
  }
};
</script>

<style lang="scss">
@import "./scss/_navigation.scss";
</style>
