export const routes = [
  {
    path: "/recruiting/dashboard",
    name: "recruiting.dashboard",
    component: () => import("~/recruiting-system/views/dashboard/dashboard"),
    meta: {
      title: "Recruiting.Dashboard.Title"
    }
  }
];
