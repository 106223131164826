import Vue from "vue";
import router from "@/config/router";
import apolloProvider from "@/apollo/provider";
import restClients from "@/config/rest/api";
import devLogging from "./plugins/developerLogging/devLogging";
import i18n from "@/config/i18n";
import store from "@/store/store";
import "@/config/feedbacks";
import idGenerator from "./plugins/componentIdGenerator/idGenerator";
import dateTimeFormatter from "./plugins/dateTimeFormatter/dateTimeFormatter";

import { initializeValidation } from "@/config/init-scripts/validation/validation";

// Einstiegskomponente laden
import App from "@/App.vue";
import Loader from "+/components/loader/Loader.vue";

Vue.config.productionTip = false;
Vue.use(devLogging);
Vue.use(idGenerator);
Vue.use(dateTimeFormatter);

new Vue({
  render: (render) => render(Loader)
}).$mount("a-pre-loader");

Vue.prototype.$rest = restClients;

export default () => {
  const app = new Vue({
    apolloProvider,
    router,
    i18n,
    store,
    render: (render) => render(App)
  });
  window.atom = app;
  initializeValidation(app);
  return app;
};
