/* eslint no-console: "off" */
export default () => {
  window.atomlog = (message, consoleType) => {
    if (
      (process && process.env && process.env.NODE_ENV === "development") ||
      window.isAtomLogingEnabled
    ) {
      const type = ["log", "warn", "error", "info", "table"].includes(
        consoleType
      )
        ? consoleType
        : "log";
      console[type](message);
    }
  };

  /**
   * Funktionen um die Konsolenausgabe der atomlog Funktion für production oder staging ein- / ausschalten zu können.
   * Muss bei bedarf manuell in der console aufgerufen werden.
   */
  window.enableAtomLoging = () => (window.isAtomLogingEnabled = true);
  window.disableAtomLoging = () => (window.isAtomLogingEnabled = false);
};
