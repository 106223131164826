<template>
  <div
    v-if="visible"
    ref="dropdownItem"
    class="dropdown-item"
    data-bs-toggle="tooltip"
    :class="{ 'text-pf-muted': disabled }"
    :title="tooltip"
    @click="emitClick"
    v-on="getAllowedCallbacks()"
  >
    <span :class="{ disabled }" v-text="label" />
  </div>
</template>
<script>
/*global bootstrap*/ // Eslint no-undef rule

export default {
  name: "ADropdownAction",
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: ""
    },
    events: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  mounted() {
    this.initializeTooltip();
  },
  methods: {
    emitClick() {
      this.$emit("click", this.value);
    },
    getAllowedCallbacks() {
      return this.disabled ? {} : this.events;
    },
    initializeTooltip() {
      if (this.tooltip) {
        new bootstrap.Tooltip(this.$refs.dropdownItem);
      }
    }
  }
};
</script>
