import {
  getAttributeFromToken,
  hasResourceRole,
  getSecurityApi
} from "@/config/keycloak/keycloak.js";

let locale = "de";

const setLocale = (_locale) => (locale = _locale);

export const getLocale = () => locale;

const loadFallbackLocale = (app) => {
  app.$rest.persis
    .defaultLocale()
    .then(({ data }) => {
      if (data && data.locale) {
        app.$i18n.fallbackLocale = data.locale;
      }
    })
    .catch((error) => {
      window.atomlog("loadFallbackLocaleEndPoint: " + error, "error");
    });
};

/**
 * Setzt das aus dem Token gelade Locale in der i18n Lib
 */
export const initializeLocale = (app) => {
  loadFallbackLocale(app);
  app.$i18n.locale = getLocale();
  document.querySelector("html").setAttribute("lang", getLocale());
};

/**
 * Läd das Benutzerprofil von Keycloak, hinterlegt es im Token und speichert das Locale in einem Zwischenspeicher
 * @param app Root Vue Instance
 */
export const registerUserProfile = () => {
  const keycloakUserProfilePromise =
    getSecurityApi().keycloak.loadUserProfile();
  return keycloakUserProfilePromise.then((profile) => {
    if (profile.attributes && profile.attributes.locale) {
      setLocale(profile.attributes.locale[0]);
    }
  });
};

/**
 * Gibt den Benutzernamen aus dem Keycloak Profil zurück
 * @returns {string} Vollständiger Name des Benutzers
 */
export const getUserName = () => {
  return getSecurityApi().user.get().fullName;
};

/**
 * Prüft ob im Token des Users das Attribut 'impersonator' vorhanden ist.
 * Ist dies der Fall, so wird der User gerade impersionifiziert
 * @returns {boolean} true Wenn der User aktuell impersionifiziert ist
 */
export const isUserImpersoniated = () => {
  return getSecurityApi().user.get().impersonator !== undefined;
};

/**
 * Gibt die employeeId der Users zurück
 * @returns {string} employeeId
 */
export const getEmployeeId = () => getAttributeFromToken("employeeId") || "";

export const getLoggedUserName = () => {
  return getSecurityApi().user.get().loggedUserName;
};

/**
 * Gibt den vollständigen Namen des Users zurück, wie er im Token hinterlegt ist
 * @returns {string} name
 */
export const getUserFullName = () => {
  return getAttributeFromToken("name");
};

/**
 * Gibt die Email Adresse des Users aus dem Token zurück
 * @returns {string} email
 */
export const getUserEmail = () => {
  return getAttributeFromToken("email");
};

/**
 * Prüft ob der in Keycloak hinterlegte userType auf 'Persis-Employee' gesetzt ist
 * @returns {boolean} true Wenn der userType 'Persis-Employee' ist
 */
export const isPersisEmployee = () => {
  const persisType = getSecurityApi().user.get().persisUserType;
  if (persisType) {
    return persisType instanceof Array
      ? persisType[0] === "Persis-Employee"
      : persisType === "Persis-Employee";
  } else {
    return persisType === "Persis-Employee";
  }
};

export const hasRole = (role, client) => {
  return hasResourceRole(role, client);
};

/**
 * Prüft ob der User die angefragte Rolle im 'realm-management' hat
 * @param  {string} role
 * @returns {boolean} true Wenn die Rolle vorhanden ist
 */
export const hasManagementRole = (role) => {
  return hasRole(role, "realm-management");
};
