export const getMapedMenuData = (data) => {
  if (data) {
    let menuList = [];
    data.forEach((menuItem) => {
      let menuData = {
        title: menuItem.title
      };
      setExternal(menuData, menuItem);
      setHref(menuData, menuItem);
      setModuleIcon(menuData, menuItem);
      setAttributes(menuData, menuItem);
      setConfiguredCssClass(menuData, menuItem);

      if (menuItem.hasOwnProperty("children") && menuItem.children.length > 0) {
        menuData.child = getMapedMenuData(menuItem.children);
      }

      menuList.push(menuData);
    });
    return menuList;
  }
  return [];
};

const getModuleIcon = (module) => ({
  element: "div",
  class: "nav-module nav-module-" + module
});

const setAttributes = (menuData, menuItem) => {
  menuData.attributes = {
    "data-key": menuItem.id
  };
};

const setExternal = (menuData, menuItem) => {
  if (menuItem.hasOwnProperty("external") && menuItem.external) {
    menuData.external = true;
  }
};

const setModuleIcon = (menuData, menuItem) => {
  if (menuItem.hasOwnProperty("module")) {
    menuData.icon = getModuleIcon(menuItem.module);
  }
};

const setConfiguredCssClass = (menuData, menuItem) => {
  if (menuItem.hasOwnProperty("class")) {
    menuData.class = menuItem.class;
  }
};

const setHref = (menuData, menuItem) => {
  if (menuItem.hasOwnProperty("path")) {
    if (menuItem.hasOwnProperty("children") && menuItem.children.length > 0) {
      menuData.href = undefined;
      return;
    } if (menuData.external) {
      menuData.path = menuItem.path;
      menuData.href = undefined;
      return;
    }
    menuData.href = menuItem.path;
  }
};

export const getAdjustedExternalUrl = (url) => {
  if(
    url &&
    !url.startsWith("http://") &&
    !url.startsWith("https://") &&
    !url.startsWith("/persis/")
  ) {
    return `http://${url}`;
  } else {
    return url;
  }
}
