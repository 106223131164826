import { persis as restClient } from "~/persis-system/config/restClient";
import { getLocale } from "@/config/keycloak/keycloakUser.js";
import moment from "moment";
let initTranslationData = undefined;

export const loadTranslations = () =>
  restClient
    .translations(getLocale())
    .then(({ data }) => {
      initTranslationData = data;
    })
    .catch((error) => {
      window.atomlog("login restClient translationEndPoint: " + error, "error");
    });

const loadI18nSettings = (app) => {
  restClient
    .i18nSettings()
    .then(({ data }) => {
      app.$i18n.mergeLocaleMessage(getLocale(), { i18nSettings: data });
    })
    .catch((error) => {
      window.atomlog(
        "login restClient i18nSettingsEndPoint: " + error,
        "error"
      );
    });
};

export const initializeTranslations = (app) => {
  const currentLocale = getLocale();
  app.$i18n.setLocaleMessage(currentLocale, initTranslationData);
  loadI18nSettings(app);
  moment.locale(currentLocale);
  initTranslationData = undefined;
};
