import { ApolloLink } from "apollo-link";
import { getKeycloakToken } from "@/config/keycloak/keycloak.js";

export default new ApolloLink((operation, forward) => {
  const token = getKeycloakToken();

  operation.setContext({
    headers: {
      "Accept-Charset": "UTF-8",
      authorization: token ? `Bearer ${token}` : ""
    }
  });

  return forward(operation);
});
