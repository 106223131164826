import { createRestClientByModuleName } from "@/config/rest/restUtils";

const configSystemClient = createRestClientByModuleName("config-system");

export const config = {
  saveUserImage: (imageSrc) =>
    configSystemClient.post("/profile/picture", imageSrc, {
      headers: {
        "Content-Type": `image/jpeg`
      }
    }),
  deleteUserImage: () => configSystemClient.delete("/profile/picture"),
  getSystemSetting: (varName) => configSystemClient.get(`/setting/${varName}`),
  getSecretFlag: () => configSystemClient.get("/profile/picture/secret_flag"),
  setSecretFlag: (secretFlag) =>
    configSystemClient.post("/profile/picture/secret_flag", secretFlag, {
      headers: {
        "Content-Type": `application/json`
      }
    })
};
