const state = {
  currentIframeUrl: "",
  lastNavigationUrl: ""
};

const getters = {
  currentIframeUrl: (state) => state.currentIframeUrl,
  lastNavigationUrl: (state) => state.lastNavigationUrl
};

const mutations = {
  setCurrentIframeUrl: (state, currentIframeUrl) =>
    (state.currentIframeUrl = currentIframeUrl),
  setLastNavigationUrl: (state, lastNavigationUrl) =>
    (state.lastNavigationUrl = lastNavigationUrl)
};

const actions = {
  setCurrentIframeUrl: ({ commit }, payload) => {
    commit("setCurrentIframeUrl", payload);
  },
  setLastNavigationUrl: ({ commit }, payload) => {
    commit("setLastNavigationUrl", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
