export const routes = [
  {
    path: "/config/userprofile",
    name: "config.userprofile",
    component: () => import("../views/profile/user-profile.vue"),
    meta: {
      title: "Config.UserProfile.Title"
    }
  },
  {
    path: "/config/userprofile/change-password",
    name: "config.userprofile.change-password",
    component: () => import("../views/profile/password/change-password.vue"),
    meta: {
      title: "Config.UserProfile.ChangePassword.Title"
    }
  },
  {
    path: "/config/userprofile/profil-preferences",
    name: "config.userprofile.profil-preferences",
    component: () =>
      import("../views/profile/profil-preferences/profil-preferences.vue"),
    meta: {
      title: "Config.UserProfile.ProfilePreferences.Title"
    }
  }
];
