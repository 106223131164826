import { persis as restClient } from "~/persis-system/config/restClient";
import { getRealm } from "../keycloak/keycloak";
import { getKeycloakIdToken } from "../keycloak/keycloak";

export const verifyEmployeeId = () =>
  restClient
    .verifyEmployeeId()
    .then(({ data }) => {
      if (!data) {
        redirectToSessionErrorPage();
      }
    })
    .catch(redirectToSessionErrorPage);

const redirectToSessionErrorPage = () => {
  window.location.href =
    "/errors/session-error.html" +
    "?realm=" +
    encodeURIComponent(getRealm()) +
    "&id_token=" + getKeycloakIdToken();
};
