<template>
  <button
    v-if="visible"
    id="devInfoButton"
    v-t="'SystemMenuCol.ToolDialogTitle'"
    type="button"
    class="dropdown-item"
    @click="click"
  />
</template>

<script>
export default {
  data: () => ({
    visible: false
  }),
  watch: {
    $route() {
      this.visible = false;
    }
  },
  mounted() {
    window.addEventListener("message", this.setVisibleByIframe, false);
  },
  methods: {
    click() {
      let fusionIframe = document.getElementById("persisMain");
      if (fusionIframe) {
        fusionIframe.contentWindow.postMessage("devInfoButtonClick", "*");
        const adminsistrationViewIframe = fusionIframe.contentDocument.querySelector("[data-administration-view-iframe]");
        if(adminsistrationViewIframe) {
          adminsistrationViewIframe.contentWindow.postMessage("devInfoButtonClick", "*");
        } 
      }
    },

    setVisibleByIframe(event) {
      if (event.data == "devInfoButton") this.visible = true;
    }
  }
};
</script>
