<template>
  <div ref="dropdown" class="dropdown">
    <button
      :id="id"
      :class="triggerClass"
      type="button"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <slot name="triggerContent">
        <span v-if="labelIsNotEmpty()" v-text="label" />
        <i v-if="iconIsNotEmpty()" :class="getIconClass()" />
      </slot>
    </button>
    <div class="dropdown-pf-menu dropdown-menu dropdown-menu-right" :aria-labelledby="id">
      <slot>
        <a-dropdown-action
          v-for="(dropdownAction, index) in dropdownActions"
          :key="index"
          :value="dropdownAction.value"
          :label="dropdownAction.label"
          :tooltip="dropdownAction.tooltip"
          :visible="dropdownAction.visible"
          :disabled="dropdownAction.disabled"
          :events="dropdownAction.events"
          @click="actionClick"
        />
      </slot>
    </div>
  </div>
</template>

<script>
/*global bootstrap*/ // Eslint no-undef rule
import uniqid from "uniqid";
import ADropdownAction from "./DropdownAction.vue";

export default {
  components: {
    "a-dropdown-action": ADropdownAction
  },
  props: {
    id: {
      type: String,
      default: () => uniqid("dropdown-")
    },
    triggerClass: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    dropdownActions: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data: () => ({
    dropdown: {
      type: {},
      default: () => {}
    }
  }),
  mounted() {
    this.dropdown = new bootstrap.Dropdown(this.$refs.dropdown);
    window.addEventListener("message", this.closeDropdownOnPostMessage);
  },
  methods: {
    closeDropdownOnPostMessage(event) {
      if (event.data == "mainIframeClick") {
        this.closeDropdown();
      }
    },
    closeDropdown() {
      this.dropdown.dispose();
    },
    actionClick(valueOfAction) {
      this.$emit("click", valueOfAction);
      this.closeDropdown();
    },
    iconIsNotEmpty() {
      return this.icon != "";
    },
    labelIsNotEmpty() {
      return this.label != "";
    },
    getIconClass() {
      var preparedClassName = "";
      if (this.iconIsNotEmpty()) {
        preparedClassName = this.icon;
        if (this.labelIsNotEmpty()) {
          preparedClassName += " ms-1";
        }
      }
      return preparedClassName;
    }
  }
};
</script>
