import moment from "moment";
import "moment-jdateformatparser";

export default (Vue) => {
  Vue.prototype.$dtFormat = function (date, javaFormatString) {
    let format = this.$t("i18nSettings.date-format");

    if (javaFormatString) {
      format = this.$te(javaFormatString)
        ? this.$t(javaFormatString)
        : javaFormatString;
    }
    return moment(date).formatWithJDF(format);
  };
};
