import { initKeycloackTokenInterceptor } from "./restUtils";
import { recruiting } from "~/recruiting-system/config/restClient";
import { persis } from "~/persis-system/config/restClient";
import { security } from "~/security-system/config/restClient";
import { config } from "~/config-system/config/restClient";

initKeycloackTokenInterceptor();

export default {
  recruiting,
  persis,
  security,
  config
};
