import "core-js/stable";
import "regenerator-runtime/runtime";

// Abhängigkeiten und Konfiguration laden
import "@/global-helper/plattformHelper.js";
import "@/config/init-scripts/meta";
import "+/scss/main.scss";
import {
  loadTranslations,
  initializeTranslations
} from "@/config/init-scripts/translations";
import { verifyEmployeeId } from "@/config/init-scripts/verify-employee-id";
import { keycloakOnReady } from "@/config/keycloak/keycloak.js";
import {
  registerUserProfile,
  initializeLocale
} from "@/config/keycloak/keycloakUser.js";
import createAtomApp from "@/vueInstance";
import { loadAcceptedImageTypes } from "@/config/init-scripts/validation/utils";

const setDomInformation = () => {
  document.atomDomSettings = {
    isPersisAtomApp: true
  };
};

/**
 * Initialiserungen für die Vue Root Instanz, sobald der User von Keycloak authentifiziert wurde
 */
const initializeAppIfUserIsAuthorized = async () => {
  setDomInformation();
  await verifyEmployeeId();
  await registerUserProfile();
  await loadAcceptedImageTypes();
  await loadTranslations();

  const app = createAtomApp();
  initializeLocale(app);
  initializeTranslations(app);
  app.$mount("#app");
};

/**
 * Funktion zur Fehlerbehandlung, falls die Authentifizierung durch Keycloak fehlgeschlagen ist
 */
const authorizationErrorCallback = () =>
  alert("KEYCLOAK: failed to initialize");

// KeyCloak login
keycloakOnReady(initializeAppIfUserIsAuthorized, authorizationErrorCallback);
