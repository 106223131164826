<template>
  <Teleport to="body">
    <div :id="id" ref="modal" class="modal" data-atom-modal="dialog">
      <div class="modal-dialog" :style="`max-width: ${maxWidth}`">
        <div class="modal-content">
          <div v-if="$slots['header']" class="modal-header">
            <slot name="header" />
          </div>

          <div v-if="$slots['body']" class="modal-body">
            <slot name="body" />
          </div>

          <div v-if="$slots['footer']" class="modal-footer">
            <slot name="footer">
              <button class="btn btn-pf-primary" @click="$emit('close')">
                Ok
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
/*global bootstrap*/ // Eslint no-undef rule
import Teleport from "vue2-teleport";
import uniqid from "uniqid";

export default {
  components: {
    Teleport
  },
  props: {
    id: {
      type: String,
      default: () => uniqid("modal-dialog-")
    },
    value: {
      type: Boolean,
      default: false
    },
    persist: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: "500px"
    }
  },
  data: () => ({
    dialog: {
      type: {},
      default: () => {}
    }
  }),
  mounted() {
    this.registerDialog();
    this.registerOnHiddenListener();
    this.registerOnShownListener();
  },
  updated() {
    this.value ? this.dialog.show() : this.dialog.hide();
  },
  methods: {
    registerDialog() {
      this.dialog = new bootstrap.Modal(this.$refs.modal, {
        focus: true,
        backdrop: this.persist ? "static" : true,
        keyboard: !this.persist,
        show: this.value
      });
    },
    registerOnHiddenListener() {
      this.$refs.modal.addEventListener("hidden.bs.modal", (event) => {
        this.$emit("input", false);
      });
    },
    registerOnShownListener() {
      this.$refs.modal.addEventListener("shown.bs.modal", (event) => {
        this.removeOverflowStyleFromHtmlTag();
        this.$emit("shown");
      });
    },
    removeOverflowStyleFromHtmlTag() {
      document.querySelector("html").style = "";
    }
  }
};
</script>
