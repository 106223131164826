import { createNavigationLink } from "./menuItemUtils";
import { registerMousedownEvent, registerExternalLinkMousedownEvent } from "./menuItemNavigationUtils";

export const buildPowerUserResultOption = (option, routerRef, closeDialog) => {
  const link = createNavigationLink(option);
  if(option.external) {
    registerExternalLinkMousedownEvent(link, closeDialog);
  } else {
    registerMousedownEvent(link, routerRef, closeDialog);
  }
  return link;
};

export const buildPowerUserSelectionOption = (option) => {
  const menuItem = document.createElement("span");
  menuItem.textContent = option.text;
  return menuItem;
};
