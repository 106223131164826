export const routes = [
  {
    path: `/security/user-administration/:personnelNumber?`,
    name: "user-administration",
    component: () => import("../views/user-administration/overview.vue"),
    meta: {
      title: "Security.UserAdministration.Title"
    }
  },
  {
    path: `/security/user-impersonation`,
    name: "user-impersonation",
    component: () => import("../views/user-impersonation/overview.vue"),
    meta: {
      title: "Security.UserImpersonation.Title"
    }
  },
  {
    path: "/system/feedback",
    name: "system.feedback",
    component: () => import("+/components/feedback/SystemFeedback.vue"),
    props: true
  }
];
