import { createClient } from "@/apollo/clientUtils";
import { createHttpLink } from "apollo-link-http";

const dataSourceLink = createHttpLink({
  uri: "/security-system/private/data",
    headers: {
      'content-type': 'application/json; charset=utf-8'
    }
});

export const security = createClient(dataSourceLink);
