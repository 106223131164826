import Vue from "vue";
import Vuex from "vuex";
import userProfile from "./modules/userProfile";
import loader from "./modules/loader";
import dom from "./modules/dom";
import breadcrumb from "./modules/breadcrumb";
import menu from "./modules/menu";
import mainIframeStore from "./modules/mainIframeStore";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  modules: {
    breadcrumb,
    dom,
    loader,
    menu,
    mainIframeStore,
    userProfile
  }
});
