export const routes = [
  {
    path: "/menu/sitemap",
    name: "menu.sitemap",
    component: () => import("../views/sitemap/sitemap.vue"),
    meta: {
      title: "Menu.Sitemap.Title"
    }
  }
];
