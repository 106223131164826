import { getLoggedUserName } from "@/config/keycloak/keycloakUser.js";
import { getUserEmail } from "@/config/keycloak/keycloakUser.js";
import { config as configRestClient } from "~/config-system/config/restClient";

/**
 * Check if password repects the given policies
 * returns true if yes or an error-string if not
 *
 * @param {string} password
 * @param {Array} policies
 */
export const passwordValidation = (app, password, policies) => {
  for (const policy of policies) {
    switch (policy.type) {
      case "DIGITS":
        if (
          !password.match(/[0-9]/g) ||
          password.match(/[0-9]/g).length < policy.value
        ) {
          return app.$t(`Security.PasswordValidation.Digits`, {
            0: policy.value
          });
        }
        break;
      case "LENGTH":
        if (password.length < policy.value)
          return app.$t(`Security.PasswordValidation.Length`, {
            0: policy.value
          });
        break;
      case "MAXLENGTH":
        if (password.length > policy.value)
          return app.$t(`Security.PasswordValidation.Maxlength`, {
            "0": policy.value
          });
        break;
      case "UPPERCASE":
        if (
          !password.match(/[A-Z]/g) ||
          password.match(/[A-Z]/g).length < policy.value
        )
          return app.$t(`Security.PasswordValidation.Uppercase`, {
            0: policy.value
          });
        break;
      case "LOWERCASE":
        if (
          !password.match(/[a-z]/g) ||
          password.match(/[a-z]/g).length < policy.value
        )
          return app.$t(`Security.PasswordValidation.Lowercase`, {
            0: policy.value
          });
        break;
      case "NOTUSERNAME":
        if (password === getLoggedUserName())
          return app.$t(`Security.PasswordValidation.NotUserName`);
        break;
      case "NOTEMAIL":
        if (password === getUserEmail())
          return app.$t(`Security.PasswordValidation.Notemail`);
        break;
      case "SPECIAL_CHARS":
        // check all Unicode punctuation and symbols
        if (
          !password.match(/[\p{P}\p{S}]/u) ||
          password.match(/[\p{P}\p{S}]/u).length < policy.value
        )
          return app.$t(`Security.PasswordValidation.SpecialChars`, {
            0: policy.value
          });
        break;
      case "REGEX_PATTERN":
        // check if whole password coresponds to regex (as keycloak do without prefix ^ and suffix $)
        var regex = policy.value;
        if (!regex.charAt(0) == "^") regex = "^".concat(regex);
        if (!regex.charAt(regex, length - 1) == "$") regex = regex.concat("$");
        if (!password.match(new RegExp(regex)))
          return app.$t(`Security.PasswordValidation.RegexPattern`, {
            0: policy.value
          });
        break;
      default:
    }
  }
  return true;
};

let remoteAcceptedImageTypes = [
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/bmp",
  "image/tiff",
  "image/webp"
];

/**
 * Hier wird die Variable mit 'remoteAcceptedImageTypes' mit den Einstellung 'system.upload.allowed-image-mimetypes' überschrieben
 */
export const loadAcceptedImageTypes = async () => {
  const setting = "system.upload.allowed-image-mimetypes";
  const mimetypesResponse = await configRestClient.getSystemSetting(setting);

  if (mimetypesResponse.status === 200) {
    remoteAcceptedImageTypes = mimetypesResponse.data.split(",");
  } else {
    window.atomlog(
      `getSystemSetting remote call failed with: ${error}`,
      "error"
    );
  }
}

export const acceptedImageTypes = () => remoteAcceptedImageTypes;

export const imageTypeValidation = (file, app) => {
  const errorMessage = app.$t(
    "Config.UserProfile.ProfilePreferences.WrongFileTypeError"
  );
  return (
    !file ||
    (file && remoteAcceptedImageTypes.includes(file["type"])) ||
    errorMessage
  );
};
