import { onError } from "apollo-link-error";
import { FEATBACK_TYPES } from "@/constants/validation";

export default onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors || networkError) {
    const serverErrorMessageTranslation = window.atom.$t(
      "Validations.ServerError.GraphQlAndNetworkError"
    );
    window.atom.$feedback(serverErrorMessageTranslation, {
      type: FEATBACK_TYPES.ERROR.toLowerCase()
    });

    // Fehler in der Error Console augeben
    for (const err of graphQLErrors) {
      window.atomlog(`ErrorLink - GraphQLError: ${err.message}`, "error");
    }
    if (networkError) {
      window.atomlog(
        `ErrorLink - NetworkError: ${networkError.message}`,
        "error"
      );
    }
  }
});
