import i18n from "@/config/i18n";

function initState() {
  if (state.breadcrumb.length == 0) {
    state.breadcrumb.push(getStartRoute());
    sessionStorage.setItem("breadcrumb", JSON.stringify(state.breadcrumb));
  }
}

const state = {
  breadcrumb: []
};

const getters = {
  getBreadcrumb: (state) => {
    return state.breadcrumb;
  }
};

const mutations = {
  setBreadcrumb: (state, payload) => {
    initState();
    state.breadcrumb = payload;
    sessionStorage.setItem("breadcrumb", JSON.stringify(state.breadcrumb));
  },
  addBreadcrumb: (state, payload) => {
    initState();
    let sessionBreadcrumb = JSON.parse(sessionStorage.getItem("breadcrumb"));
    state.breadcrumb = sessionBreadcrumb;
    state.breadcrumb.push(payload);
    sessionStorage.setItem("breadcrumb", JSON.stringify(state.breadcrumb));
  }
};

const actions = {
  addBreadcrumb: ({ commit }, payload) => {
    commit("addBreadcrumb", payload);
  },
  updateBreadcrumb: ({ commit, getters }, payload) => {
    let currentBreadcrumb = getters.getBreadcrumb;
    let menu = payload.menu;
    let currentItem = getCurrentRouteItem(payload.route);

    if (
      payload.route.meta != undefined &&
      payload.route.meta.useInBreadcrumb == false
    ) {
      return;
    }

    let matchInMenu = menu.find(
      (item) => getPathOnly(item.path) == currentItem.path
    );

    let matchInBreadcrumb = currentBreadcrumb.find(
      (item) => getPathOnly(item.fullPath) == currentItem.path
    );

    if (matchInMenu != undefined) {
      let newBreadcrumb = getBreadcrumbByMenu(matchInMenu, currentItem);
      commit("setBreadcrumb", newBreadcrumb);
    } else if (matchInBreadcrumb != undefined) {
      let newBreadcrumb = getBreadcrumbByExisting(
        currentBreadcrumb,
        matchInBreadcrumb,
        currentItem
      );
      commit("setBreadcrumb", newBreadcrumb);
    } else {
      commit("addBreadcrumb", currentItem);
    }

    emitPostMessageUpdateEvent();
  }
};

function emitPostMessageUpdateEvent() {
  let fusionIframe = document.getElementById("persisMain");
  if (fusionIframe) {
    fusionIframe.contentWindow.postMessage("updateBreadcrumb", "*");
  }
}

function getPathOnly(url) {
  let path = url != undefined ? url.split("?")[0] : "";
  path = path.split("#")[0];
  return path;
}

function getCurrentRouteItem(route) {
  return {
    title: route.title,
    fullPath: route.fullPath,
    path: getPathOnly(route.fullPath),
    to: route.to
  };
}

function getBreadcrumbByMenu(matchInMenu, currentItem) {
  let newBreadcrumb = [getStartRoute()];
  if (matchInMenu.level1 != undefined) {
    newBreadcrumb.push({
      title: matchInMenu.level1.title,
      fullPath: matchInMenu.level1.path,
      path: getPathOnly(matchInMenu.level1.path),
      to: matchInMenu.level1.path
    });
  }
  if (matchInMenu.level2 != undefined) {
    newBreadcrumb.push({
      title: matchInMenu.level2.title,
      fullPath: matchInMenu.level2.path,
      path: getPathOnly(matchInMenu.level2.path),
      to: matchInMenu.level2.path
    });
  }
  newBreadcrumb.push({
    title: matchInMenu.title,
    fullPath: matchInMenu.path,
    path: getPathOnly(matchInMenu.path),
    to: currentItem.to
  });
  return newBreadcrumb;
}

function getBreadcrumbByExisting(
  currentBreadcrumb,
  matchInBreadcrumb,
  currentItem
) {
  let newBreadcrumb = [];
  currentBreadcrumb.some((item) => {
    if (getPathOnly(item.fullPath) != getPathOnly(matchInBreadcrumb.fullPath)) {
      newBreadcrumb.push(item);
      return false;
    } else {
      newBreadcrumb.push(currentItem);
      return true;
    }
  });

  if (currentItem.path == getStartRoute().path) {
    newBreadcrumb = [getStartRoute()];
  }

  return newBreadcrumb;
}

function getStartRoute() {
  return {
    title: i18n.t("Components.Breadcrumb.StartPage"),
    fullPath: "/main/main",
    path: "/main/main",
    to: "main?fn=main"
  };
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
