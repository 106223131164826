export default (Vue) => {
  Vue.mixin({
    mounted() {
      renderAttributes(this);
    },
    updated() {
      renderAttributes(this);
    }
  });

  Vue.directive("translate", {
    bind: function (el, binding, vnode) {
      el.innerHTML = vnode.context.$i18n.t(binding.value);
      el.setAttribute("data-component-id", binding.value);
    }
  });
};

const renderAttributes = (vueInstance) => {
  if (vueInstance && vueInstance.$el && vueInstance.$el.setAttribute) {
    setComponentNameAttribute(vueInstance);
    setInputIdAttribute(vueInstance);
    setTextIdAttribute(vueInstance);
  }
};

const setComponentNameAttribute = (vueInstance) => {
  vueInstance.$el.setAttribute(
    "data-component-name",
    toKebab(getComponentName(vueInstance))
  );
};

const setInputIdAttribute = (vueInstance) => {
  if (vueInstance.$el) {
    vueInstance.$el
      .querySelectorAll("input, textarea, select")
      .forEach((input) => {
        if (!input.getAttribute("data-input-id")) {
          const id = generateInputIdentifier(vueInstance, input);
          input.setAttribute("data-input-id", id);
        }
      });
  }
};

const setTextIdAttribute = (vueInstance) => {
  if (vueInstance.$el) {
    vueInstance.$el
      .querySelectorAll("span, button, p, th, td")
      .forEach((elem) => {
        elem.setAttribute(
          "data-id-by-text-content",
          generateTextIdentifier(elem)
        );
      });
    vueInstance.$el.querySelectorAll("a[href]").forEach((elem) => {
      elem.setAttribute(
        "data-id-by-href-content",
        hashCode(elem.getAttribute("href"))
      );
    });
  }
};

const getComponentName = (vueInstance) =>
  vueInstance.$options.name ||
  vueInstance.$options._componentTag ||
  "atom-component";

const generateTextIdentifier = (element) => {
  return hashCode(element.textContent);
};

const generateInputIdentifier = (vueInstance, input) => {
  const parentComponentName = getComponentName(vueInstance);
  let id = toKebab(parentComponentName);

  if (input.tagName) {
    id += "-" + input.tagName.toLowerCase();
  }

  if (input.type) {
    id += "-" + input.type;
  }

  if (input.name) {
    id += "-" + input.name;
  }

  if (input.type === "checkbox" || input.type === "radio") {
    id += "-" + input.value; // Das Value Attribut sollte bei checkboxen und radio-Buttons immer hard hinterlegt sein
  }

  if (input.placeholder) {
    id += "-" + hashCode(input.placeholder);
  }

  return id;
};

const hashCode = (string) => {
  if (Array.prototype.reduce && string) {
    return string
      .split("")
      .map((v) => v.charCodeAt(0))
      .reduce((a, v) => (a + ((a << 7) + (a << 3))) ^ v)
      .toString(16);
  }
  let hash = 0;
  if (string.length === 0) return hash;
  for (let i = 0; i < string.length; i++) {
    let character = string.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash = hash & hash;
  }
  return hash;
};

const toKebab = (string) => {
  return string
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();
};
