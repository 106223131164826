export const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("../views/main/main.vue"),
    params: {
      pdefFunction: "main"
    },
    meta: {
      isLoading: true,
      useInBreadcrumb: false
    }
  },
  {
    path: "/main/:pdefFunction",
    name: "mainFn",
    component: () => import("../views/main/main.vue"),
    meta: {
      isLoading: true
    }
  },
  {
    path: "/main/:pdefFunction/:pdefAction",
    name: "mainFnAction",
    component: () => import("../views/main/main.vue"),
    meta: {
      isLoading: true
    }
  },
  {
    path: "/portal/:jobPosting",
    name: "portalPosting",
    component: () => import("../views/main/main.vue"),
    params: {
      pdefFunction: "main"
    },
    meta: {
      isLoading: true,
      useInBreadcrumb: false
    }
  }
];
