import _ from "lodash";

const state = {
  atomMenu: [],
  atomMenuFlat: [],
  isNavigationCollapsed: true
};

const getters = {
  getAtomMenu: (state) => state.atomMenu,
  getAtomMenuFlat: (state) => state.atomMenuFlat,
  isNavigationCollapsed: (state) => state.isNavigationCollapsed
};

const mutations = {
  setAtomMenu: (state, payload) => (state.atomMenu = payload),

  setAtomMenuFlat: (state, payload) =>
    (state.atomMenuFlat = flattenMenu(payload)),

  setNavigationCollapsed: (state, payload) =>
    (state.isNavigationCollapsed = payload)
};

const actions = {
  setAtomMenu: ({ commit }, payload) => {
    commit("setAtomMenu", payload);
    commit("setAtomMenuFlat", payload);
  },
  setNavigationCollapsed: ({ commit }, payload) => {
    commit("setNavigationCollapsed", payload);
  }
};

function flattenMenu(atomMenu) {
  return _.flatten(
    _.map(atomMenu, (level1) => {
      let flatChildren = _.flatMapDeep(level1.children, (level2) => {
        level2.level1 = level1;
        level2.module = level1.module;
        const level3 = _.map(level2.children, (level3) => {
          level3.level1 = level1;
          level3.level2 = level2;
          level3.module = level1.module;
          return level3;
        });

        return [level2, level3];
      });

      let filteredFlatChildren = flatChildren.filter((link) => {
        return link.path != "";
      });
      return filteredFlatChildren;
    })
  );
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
