const isApple = [
  "iPhone",
  "iPad",
  "iPod",
  "iPad Simulator",
  "iPhone Simulator"
].includes(navigator.platform);

if (isApple) {
  document.documentElement.classList.add("ios-device");
}

export const isIosDevice = isApple;
