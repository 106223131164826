import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";

import mutationLink from "./links/mutationLink";
import authLink from "./links/authLink";
import errorLink from "./links/errorLink";

export const createClient = (httpLink) => {
  return new ApolloClient({
    link: ApolloLink.from([mutationLink, errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "ignore"
      },
      query: {
        fetchPolicy: "network-only",
        errorPolicy: "all"
      },
      mutate: {
        fetchPolicy: "no-cache",
        errorPolicy: "all"
      }
    }
  });
};
