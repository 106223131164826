<template>
  <a-dialog
    id="powerUserNavigationModal"
    v-model="punDialog"
    max-width="700px"
    @shown="focusSelect"
  >
    <a-select-2
      id="powerUserNavigation"
      slot="body"
      ref="select2"
      v-model="model"
      :options="getMenuItemOptions"
      :settings="getConfigurationSettings()"
      @change="navigateToPage"
    />
  </a-dialog>
</template>

<script>
import Select2 from "v-select2-component";
import jquery from "jquery";
import Dialog from "+/components/dialog/Dialog";
import {
  buildPowerUserResultOption,
  buildPowerUserSelectionOption
} from "./utils/utils";
import {
  getAdjustedExternalUrl
} from "../navigation/utils/navigationUtils.js";

import { mapGetters } from "vuex";

export default {
  components: {
    "a-dialog": Dialog,
    "a-select-2": Select2
  },
  data() {
    return {
      punDialog: false,
      model: null
    };
  },
  computed: {
    ...mapGetters("menu", ["getAtomMenuFlat"]),
    getMenuItemOptions() {
      const options = this.getAtomMenuFlat.map((menuItem) => {
        return {
          id: menuItem.id,
          text: menuItem.title,
          module: menuItem.module,
          path: menuItem.path,
          external: menuItem.external,
          levelOneTitle: menuItem.level1.title
        };
      });
      return options;
    }
  },
  watch: {
    punDialog(newValue) {
      if (newValue) {
        this.model = undefined;
      }
    }
  },
  mounted() {
    window.addEventListener("message", this.postMessageListener, false);
    document.addEventListener("keyup", this.openNavigationListener);
    jquery(document).on("select2:open", () => {
      document.querySelector(".select2-search__field").focus();
    });
  },
  methods: {
    getConfigurationSettings() {
      return {
        theme: "bootstrap-5",
        templateResult: this.renderResultOption,
        templateSelection: this.renderSelectionOption,
        placeholder: this.$t("Components.PowerNavigation.Placeholder"),
        dropdownParent: document.getElementById("powerUserNavigationModal")
      };
    },
    focusSelect() {
      const select2Element = jquery(this.$refs.select2.$el)
        .find("select")
        .first();
      if (select2Element.length) {
        select2Element.select2("open");
      }
    },
    navigateToPage(selectedOptionId) {
      const selectedOption = this.getMenuItemOptions.find(
        (option) => option.id === selectedOptionId
      );
      if (selectedOption) {
        this.closeDialog();
        if(selectedOption.external) {
          window.open(getAdjustedExternalUrl(selectedOption.path), "_blank");
        } else {
          this.$router.push({
            path: selectedOption.path,
            query: {
              _forceNav: new Date().getTime()
            }
          });
        }
      }
    },
    closeDialog() {
      this.punDialog = false;
    },
    openDialog() {
      this.punDialog = true;
    },
    renderResultOption(option) {
      return buildPowerUserResultOption(option, this.$router, this.closeDialog);
    },
    renderSelectionOption(option) {
      return buildPowerUserSelectionOption(option);
    },
    postMessageListener(event) {
      if (event.data == "openPowerUserNavigation") {
        this.openDialog();
      }
    },
    openNavigationListener(event) {
      if (event.altKey && event.which == 80) {
        this.openDialog();
      }
    }
  }
};
</script>
