import Vue from "vue";
import VueApollo from "vue-apollo";

Vue.use(VueApollo);

import { absence } from "~/absence-system/config/apollo/environments";
import { recruiting } from "~/recruiting-system/config/apollo/environments";
import { menu } from "~/menu-system/config/apollo/environments";
import { security } from "~/security-system/config/apollo/environments";
import { config } from "~/config-system/config/apollo/environments";
import store from "@/store/store";

const clients = {
  absence,
  recruiting,
  menu,
  security,
  config
};

export default new VueApollo({
  clients,
  watchLoading(isLoading) {
    store.dispatch("loader/isLoading", isLoading);
  }
});
