import Vue from "vue";
import Snotify, { SnotifyPosition } from "vue-snotify";

import { FEATBACK_TYPES } from "@/constants/validation";

const defaultOptions = {
  timeout: 25000,
  showProgressBar: false,
  type: FEATBACK_TYPES.INFO.toLowerCase(),
  closeOnClick: true,
  bodyMaxLength: 250
};

const maxOnScreen = () => (window.innerWidth < 720 ? 1 : 4);

Vue.use(Snotify, {
  global: {
    maxOnScreen: maxOnScreen(),
    maxAtPosition: maxOnScreen()
  },
  toast: {
    position: SnotifyPosition.rightTop
  }
});

Vue.prototype.$feedback = function (message, options) {
  const mergedOptions = { ...defaultOptions, ...options };
  this.$snotify[mergedOptions.type](message, mergedOptions);
};
