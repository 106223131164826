import { getAdjustedExternalUrl } from "../../navigation/utils/navigationUtils.js";

export const registerMousedownEvent = (link, routerRef, closeDialog) => {
  link.addEventListener("mousedown", (event) => {
    const navigationUrl = getHref(link);
    if (!navigationUrl) return;

    const openIn = getOpenInOptions(event);
    if (openIn.newTab) {
      openInNewTab(routerRef, navigationUrl);
      return;
    }

    if (openIn.topFrame) {
      routerRef.push(navigationUrl);
      closeDialog();
    }
  });
};

export const registerExternalLinkMousedownEvent = (link, closeDialog) => {
  link.addEventListener("mousedown", (event) => {
    const navigationUrl = getHref(link);
    if (!navigationUrl) return;

    const openIn = getOpenInOptions(event);
    window.open(getAdjustedExternalUrl(navigationUrl), "_blank");
    closeDialog();
  });
};

const getHref = (link) => {
  return link.dataset.navigationHref;
};

const getOpenInOptions = (event) => {
  const { button: clickedMouseButton, ctrlKey: ctrlKeyIsPressed } = event;
  const isLeftMouseClick = clickedMouseButton === 0;
  const isMiddleMouseClick = clickedMouseButton === 1;

  return {
    topFrame: isLeftMouseClick,
    newTab: ctrlKeyIsPressed || isMiddleMouseClick
  };
};

const openInNewTab = (routerRef, relativeUrl) => {
  const routeData = routerRef.resolve({ path: relativeUrl });
  window.open(routeData.href, "_blank");
};
