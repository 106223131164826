const securityApi = new window.AtomKeycloak();

/* Nach dem Logout soll nach einem erneuten erfolgreichem Login die Startseite angezeigt werden.
 * Hierfür wird nach dem Logout in Fusion der History State auf die Startseite gesetzt,
 * wodurch die Startseite als Refferer für Keycloak benutzt wird.
 */
const setHistoryStateToMainPage = () =>
  history.pushState({}, "start", "/main/main");

const isFunction = (callback) => callback && typeof callback === "function";

/**
 * Erneuert das Keycloak Token
 * @param {number} minValidity Zeit in Sekunden in denen das Token ablaufen wird
 */
export const refreshToken = (minValidity = -1) =>
  securityApi.keycloak.refreshToken(minValidity);

/**
 * Gibt die SecurityApi zurück. In der SecurityApi ist die Keycloak-Adapter Instanz gekapselt.
 */
export const getSecurityApi = () => securityApi;

/**
 * Führt ein GOP- Logout durch.
 * Wenn success- und/oder errorCallbacks übergeben werden, so werden diese auch im success/error- Fall ausgeführt.
 * Werden die Callbacks nicht übergeben so wird folgendes default Verhalten ausgeführt.
 *
 *  -->  Löscht vorhandene Keycloak Tokens und führt ein redirect auf die Keycloak- Loginmaske durch
 */
export const keycloakLogout = (
  successCallback,
  errorCallback,
  onlyPersisLogout = false
) => {
  const logoutUrl = `/persis/main?fn=logout${
    onlyPersisLogout ? "&onlyPersisLogout=1" : ""
  }`;
  fetch(logoutUrl).then((response) => {
    setHistoryStateToMainPage();
    if (response.status === 200) {
      if (isFunction(successCallback)) {
        successCallback();
      }
      if (onlyPersisLogout) {
        refreshToken();
      } else {
        clearKeycloakToken(); // leitet wegen dem 'login-required'- Parameter auf die Login-Seite von Keycloak weiter
      }
    } else {
      if (isFunction(errorCallback)) {
        errorCallback();
      }
    }
  });
};

const clearKeycloakToken = securityApi.keycloak.clearToken;

/**
 * Registriert die übergebenen Callback Funktionen im Ready-Event von Keycloak.
 *
 * @param  {function} authenticatedCallback Wird ausgeführt wenn der User in Keycloak authentifiziert wurde
 * @param  {function} errorCallback Wird ausgeführt wenn die Authentifizierung fehlgeschlagen ist
 */
export const keycloakOnReady = (authenticatedCallback, errorCallback) => {
  clearKeycloakToken();
  securityApi.keycloak
    .init(authenticatedCallback, errorCallback)
    .catch((error) => window.atomlog("keycloak init error: " + error, "error"));
};

/**
 * Liefert das angegeben Attribut aus dem geparsten Keycloak Token zurück
 * @param {string} attributeName Name des Attributs das im Keycloak Token hinterlegt ist
 * @returns {string} string
 */
export const getAttributeFromToken = (attributeName) => {
  return securityApi.tokens.get().tokenParsed[attributeName];
};

/**
 * Liefert das angegeben Attribut aus dem Keycloak Profile zurück
 * @param {string} attributeName Name des Attributs das im Keycloak Token hinterlegt ist
 * @returns {string} string
 */
export const getAttributeFromProfile = (attributeName) => {
  const profile = securityApi.keycloak.profile();
  return profile && profile.attributes ? profile.attributes[attributeName] : "";
};

/**
 * Liefert den aktuellen Keycloak Token zurück
 * @returns {string} string
 */
export const getKeycloakToken = () => securityApi.tokens.get().token;

/**
 * Liefert den Keycloak ID Token zurück
 * @returns {string} string
 */
export const getKeycloakIdToken = () => securityApi.tokens.get().idToken;

/**
 * Prüft ob der User die angegebene Rolle für den angegeben Client hat
 * @param  {string} role (z.B manage-users)
 * @param  {string} client (z.B realm-management)
 * @returns {boolean} boolean
 */
export const hasResourceRole = (role, client) => {
  return securityApi.keycloak.hasResourceRole(role, client);
};

export const getRealm = () => {
  return securityApi.meta.get().realm;
};

export const createLogoutUrl = (options) => {
  return securityApi.keycloak.createLogoutUrl(options);
};
